import axios from "axios";
import { useDebounceFn } from "@vueuse/core";
import {
  CookieTokenName,
  getCookieToken,
  SetCookieToken,
} from "@/helpers/cookies.helper";

const updateToken = useDebounceFn(
  () => {
    const token = getCookieToken(CookieTokenName);
    SetCookieToken({
      cname: CookieTokenName,
      token: token,
      exDays: 1,
    });
  },
  300000,
  { maxWait: 300000 }
);

const axiosCreate = (baseUrl: string) => {
  const instance = axios.create({
    baseURL: baseUrl,
  });

  instance.interceptors.response.use(
    function (response) {
      updateToken();
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  
  return instance;
};

export const httpClient = axiosCreate("https://api.myperfit.com/");

export const httpFirebase = axiosCreate("https://us-central1-perfit-internal-tools.cloudfunctions.net/");

export const httpIntegrations = axiosCreate("https://private-gw.prod.elb.myperfit.com/integrations/");

export const httpPeriodicReport = axiosCreate("https://private-gw.prod.elb.myperfit.com/accountmetrics/");

export const httpRefreshIntegration = axiosCreate("https://private-gw.prod.elb.myperfit.com/integrations/");

export const httpRefreshCDV = axiosCreate("https://private-gw.prod.elb.myperfit.com/orders/"); 

export const httpStopCampaign = axiosCreate("https://private-composer.prod.elb.myperfit.com/throttlemanager/");

export const httpValidateCategory = axiosCreate("https://private-gw.prod.elb.myperfit.com/integrations/tiendanube/"); 

export const httpValidateContact = axiosCreate("https://private-composer.prod.elb.myperfit.com/emailvalidator/");

export const httpValidateDomain = axiosCreate("https://private-gw.prod.elb.myperfit.com/fromauthvalidator/perfit/");

export const httpRelateAccount = axiosCreate("https://private-gw.prod.elb.myperfit.com/integrations/tiendanube/accountrelations/");

export const httpCreateCoupon = axiosCreate("https://pem-lb.myperfit.com/v2/coupons");  

export const httpPEM = axiosCreate("https://pem-lb.myperfit.com/v2"); 